import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContext } from './contexts/ToastContext';

import { Payment } from './views/Payment';
import { SignDocument } from './views/SignDocument';
import { VideoEnrollment } from './views/VideoEnrollment';
import { VideoVerification } from './views/VideoVerification';

import './App.css';

function App() {
    const notify = (msg) => toast(msg);

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <ToastContext.Provider value={{ notify }}>
                <Router>
                    <Switch>
                        <Route path="/" exact render={() => <Redirect to="/e-sign" />} />
                        <Route path="/e-sign" component={SignDocument} />
                        <Route path="/payment" component={Payment} />
                        <Route path="/video-enrollment" component={VideoEnrollment} />
                        <Route path="/video-verification" component={VideoVerification} />
                    </Switch>
                </Router>
                <ToastContainer />
            </ToastContext.Provider>
        </Worker>
    );
}

export default App;
