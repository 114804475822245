import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

import constants from '../utils/constants';
import { ESignService } from '../services/sign.service';

import { ToastContext } from '../contexts/ToastContext';

import Met1LogoImg from '../assets/img/meta1-logo.png';

let popupWindow;

export const Payment = () => {
    const [username, setUsername] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userToken, setUserToken] = useState('');
    const [paymentRedirectUrl, setPaymentRedirectUrl] = useState('');
    const [paymentResult, setPaymentResult] = useState(constants.STATUS.NONE);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();

    const { notify } = useContext(ToastContext);

    // get username and userEmail from e-Sign page
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const name = queryParams.get('name');
        name && setUsername(name);

        const email = queryParams.get('email');
        console.log(email);
        email && setUserEmail(email);

        const token = queryParams.get('token');
        token && setUserToken(token);

        const redirectUrl = queryParams.get('redirectUrl');
        redirectUrl && setPaymentRedirectUrl(redirectUrl);
    }, [location]);

    // check payment result after redirect
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        localStorage.setItem(constants.PAYMENT_RESULT, status);
    }, [location]);

    const openPopupWindow = (url) => {
        const options = 'toolbar=0,status=0,resizable=1,width=626,height=626';
        popupWindow = window.open(url, 'sharer', options);
    };

    const onPaymentDone = (ev) => {
        if (ev.key === constants.PAYMENT_RESULT) {
            const result = localStorage.getItem(constants.PAYMENT_RESULT);
            setPaymentResult(result);
            popupWindow?.close();
        }
    };

    useEffect(() => {
        window.addEventListener('storage', onPaymentDone);

        return () => {
            window.removeEventListener('storage', onPaymentDone);
        };
    }, []);

    useEffect(() => {
        if (paymentResult === constants.STATUS.FAILURE) {
            notify('Payment Request failed, please try again.');
            return;
        } else if (paymentResult === constants.STATUS.SUCCESS) {
            window.location.href = `${paymentRedirectUrl}?signature=success`;
        }
    }, [notify, paymentRedirectUrl, paymentResult]);

    const onPay = useCallback(async () => {
        setIsLoading(true);

        const [getPaymentUrlResponse, getPaymentUrlError] = await ESignService.getPaymentUrl({
            token: userToken,
            name: username,
            email: userEmail,
        });

        if (getPaymentUrlError) {
            console.error(getPaymentUrlError);
        } else {
            if (getPaymentUrlResponse?.data?.url) {
                openPopupWindow(getPaymentUrlResponse?.data?.url);
            }
        }

        setIsLoading(false);
    }, [userEmail, userToken, username]);

    return (
        <>
            <Container>
                {[constants.STATUS.NONE, constants.STATUS.FAILURE].includes(paymentResult) ? (
                    <>
                        <Row className="mt-5">
                            <Col className="d-flex">
                                <img
                                    alt="META 1 Logo"
                                    src={Met1LogoImg}
                                    className="mx-auto w-400"
                                />
                            </Col>
                        </Row>
                        <h4 className="my-3 text-center">Pay Membership Dues with Stripe</h4>
                        <Row>
                            <Col sm="12" className="d-flex justify-center py-2">
                                <p>$1.00 META Private Membership Association Lifetime Membership</p>
                            </Col>
                            <Col sm="12" className="d-flex justify-center py-2">
                                <Button
                                    color="success"
                                    style={{ width: '240px' }}
                                    disabled={isLoading}
                                    onClick={onPay}
                                >
                                    {isLoading ? 'Loading' : 'Pay now'}
                                </Button>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row className="mt-5">
                        <Col className="pt-5">
                            <p
                                style={{
                                    fontSize: '22px',
                                    fontWeight: '400',
                                    lineHeight: '1.25em',
                                    textAlign: 'center',
                                }}
                            >
                                Thank you for becoming of member of the META Private Membership
                                Association. You can close this screen and return to the wallet
                                creation window.
                            </p>
                        </Col>
                    </Row>
                )}
            </Container>
        </>
    );
};
