import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

import { useScript } from '../hooks/useScript';
import { ToastContext } from '../contexts/ToastContext';
import { useGetVoiceItPhrases } from '../hooks/useGetVoiceItPhrases';

import { ESignService } from '../services/sign.service';
import { VoiceItService } from '../services/voiceit.service';

import constants from '../utils/constants';

import VideoVerificationImg from '../assets/img/video-verification.jpg';

let VoiceItClient;

const { REACT_APP_VOICE_IT_API_URL, REACT_APP_VOICE_IT_CONTENT_LANGUAGE } = process.env;

export const VideoVerification = () => {
    const [userEmail, setUserEmail] = useState('');
    const [verificationRedirectUrl, setVerificationRedirectUrl] = useState('');

    const { phrases } = useGetVoiceItPhrases(REACT_APP_VOICE_IT_CONTENT_LANGUAGE);

    const { notify } = useContext(ToastContext);

    const history = useHistory();
    const location = useLocation();

    // get verification redirectUrl from local storage when user come back from enrollment page
    useEffect(() => {
        const redirectUrl = window.localStorage.getItem(
            constants.LOCAL_STORAGE_KEY.VERIFICATION_REDIRECT_URL
        );
        if (redirectUrl) {
            setVerificationRedirectUrl(redirectUrl);
        }
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const email = searchParams.get('email');
        setUserEmail(email);

        const redirectUrl = searchParams.get('redirectUrl');
        setVerificationRedirectUrl(redirectUrl);
        if (redirectUrl) {
            window.localStorage.setItem(
                constants.LOCAL_STORAGE_KEY.VERIFICATION_REDIRECT_URL,
                redirectUrl
            );
        }
    }, [location]);

    const voiceItScriptLoadStatus = useScript('./voiceit2.min.js');

    const fetchVoiceItUserId = useCallback(async () => {
        if (!userEmail) return;

        const [voiceItUserIdRes, voiceItUserIdError] = await ESignService.getProfile(userEmail);

        // redirect user to enrollment page if voiceIt userId doesn't exist
        if (voiceItUserIdError) {
            notify('You have to complete Video Enrollment first, please try after that.');
            setTimeout(() => {
                history.push(`/video-enrollment?email=${userEmail}&success=verification`);
            }, constants.TIME_DELAY.LONG);
            return;
        }

        const [voiceItTokenRes] = await VoiceItService.generateUserToken(
            voiceItUserIdRes.data?.status?.voiceitID
        );

        voiceItTokenRes && VoiceItClient.setSecureToken(voiceItTokenRes.data);
    }, [history, notify, userEmail]);

    useEffect(() => {
        if (voiceItScriptLoadStatus === 'ready' && userEmail) {
            // eslint-disable-next-line no-undef
            VoiceItClient = new VoiceIt2.initialize(
                `${REACT_APP_VOICE_IT_API_URL}/init`,
                REACT_APP_VOICE_IT_CONTENT_LANGUAGE
            );
            VoiceItClient.setThemeColor('#0000FF');

            fetchVoiceItUserId();
        }
    }, [fetchVoiceItUserId, userEmail, voiceItScriptLoadStatus]);

    const onNeedEnrollments = () => {
        notify('A minimum of three enrollments are needed');
        history.push(`/video-enrollment?email=${userEmail}&success=verification`);
    };

    const onVerificationSuccess = async (success, jsonResponse) => {
        console.log('VoiceIt JSON Response: ', jsonResponse);
        if (success) {
            notify('Successfully verified face and voice!');
        } else {
            notify('Video Verification Cancelled or Failed!');
        }

        if (verificationRedirectUrl) {
            const [verificationResultJwt] = await ESignService.saveVideoVerificationHistory({
                email: userEmail,
                status: success ? constants.STATUS.SUCCESS : constants.STATUS.FAILURE,
            });
            window.location.href = `${verificationRedirectUrl}?token=${verificationResultJwt}`;
        }
    };

    const onVideoVerify = () => {
        VoiceItClient.encapsulatedVideoVerification({
            // Set to true to present user with liveness challenges
            doLiveness: true,
            contentLanguage: REACT_APP_VOICE_IT_CONTENT_LANGUAGE,
            phrase: phrases[0].text,
            needEnrollmentsCallback: onNeedEnrollments,
            completionCallback: onVerificationSuccess,
        });
    };

    return (
        <Container className="h-100vh pt-80">
            <Row>
                <Col sm="12" className="my-3 py-3" style={{ display: 'flex' }}>
                    <img
                        alt="Video Verification Logo"
                        src={VideoVerificationImg}
                        style={{ width: '400px' }}
                        className="mx-auto br-50"
                    />
                </Col>
                <Col sm="12" className="my-3 py-3" style={{ display: 'flex' }}>
                    <Button color="success" className="mx-auto w-240" onClick={onVideoVerify}>
                        Verify Video
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
