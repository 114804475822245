import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import HashLoader from 'react-spinners/HashLoader';

import constants from '../utils/constants';
import { generateQueryString } from '../utils/utils';

import { ESignService } from '../services/sign.service';

import { ToastContext } from '../contexts/ToastContext';
import { SignatureModal } from '../components/SignatureModal/SignatureModal';

import Met1LogoImg from '../assets/img/meta1-logo.png';

export const SignDocument = () => {
    const [userProfileLoadStatus, setUserProfileLoadStatus] = useState(
        constants.LOADING_STATUS.NONE
    );

    const [username, setUsername] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [userToken, setUserToken] = useState('');
    const [signRedirectUrl, setSignRedirectUrl] = useState('');

    const [pagesCount, setPagesCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [isLastPageVisited, setIsLastPageVisited] = useState(false);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { notify } = useContext(ToastContext);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const history = useHistory();
    const location = useLocation();

    // show nothing until get useInfo with email
    const fetchUserProfile = useCallback(async () => {
        setUserProfileLoadStatus(constants.LOADING_STATUS.LOADING);

        if (!userToken) {
            setUserProfileLoadStatus(constants.LOADING_STATUS.DONE);
            return;
        }

        const [profileResponse, profileError] = await ESignService.getProfile(userEmail);
        if (profileError) {
            notify(profileError.message);
        } else {
            if (profileResponse?.data?.status?.isSign) {
                const queryString = generateQueryString({
                    name: username,
                    email: userEmail,
                    token: userToken,
                    redirectUrl: signRedirectUrl,
                });

                console.log('queryString: ', queryString);

                history.push(`/payment?${queryString}`);
            }
        }

        setUserProfileLoadStatus(constants.LOADING_STATUS.DONE);
    }, [history, notify, signRedirectUrl, userEmail, userToken, username]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const firstName = params.get('firstName');
        const lastName = params.get('lastName');
        const email = params.get('email');
        const phoneNumber = params.get('phoneNumber');
        const token = params.get('token');
        const redirectUrl = params.get('redirectUrl');
        const name = [firstName, lastName].join(' ');

        setUsername(name);
        setUserEmail(email);
        setUserPhoneNumber(phoneNumber);
        setUserToken(token);
        setSignRedirectUrl(redirectUrl);

        email ? fetchUserProfile() : setUserProfileLoadStatus(constants.LOADING_STATUS.DONE);
    }, [location, fetchUserProfile]);

    useEffect(() => {
        if (pageIndex + 1 === pagesCount) {
            setIsLastPageVisited(true);
        }
    }, [pageIndex, pagesCount]);

    const onPageChange = ({ currentPage }) => setPageIndex(currentPage);

    const onDocumentLoad = ({ doc }) => setPagesCount(doc._pdfInfo.numPages);

    const onSignatureModalOpen = () => setIsSignatureModalOpen(true);

    const onSignatureDraw = async (signature, address) => {
        setIsSignatureModalOpen(false);

        if (!signature || !address) return;

        setIsLoading(true);

        // eslint-disable-next-line no-unused-vars
        const [ignore, signDocumentError] = await ESignService.signDocument({
            token: userToken,
            payload: { image: signature, ...address },
        });

        if (signDocumentError) {
            notify(signDocumentError.message);
            window.location.href = `${signRedirectUrl}?signature=failure`;
        } else {
            notify('Document signed successfully');
            const queryString = generateQueryString({
                name: username,
                email: userEmail,
                token: userToken,
                redirectUrl: signRedirectUrl,
            });

            history.push(`/payment?${queryString}`);
        }

        setIsLoading(false);
    };

    return (
        <>
            {userProfileLoadStatus === constants.LOADING_STATUS.DONE ? (
                <>
                    <Container>
                        <Row>
                            <Col sm="12" className="d-flex py-2">
                                <img
                                    alt="META 1 Logo"
                                    src={Met1LogoImg}
                                    className="mx-auto w-400"
                                />
                            </Col>
                            <Col sm={{ size: 6, offset: 3 }}>
                                <p style={{ textAlign: 'center' }}>
                                    Please review and sign the META Private Membership Agreement,
                                    this is required to use our platform or any of META’s services.
                                </p>
                            </Col>
                            <Col sm="12" className="d-flex py-2">
                                <Button
                                    color="warning"
                                    className="ml-auto"
                                    disabled={!isLastPageVisited || isLoading}
                                    onClick={onSignatureModalOpen}
                                >
                                    {isLoading ? 'Processing' : 'Draw Signature'}
                                </Button>
                            </Col>
                            <Col sm="12">
                                <div style={{ height: 'calc(100vh - 240px)' }}>
                                    <Viewer
                                        fileUrl={`pdf-demo.pdf`}
                                        plugins={[defaultLayoutPluginInstance]}
                                        initialPage={pageIndex}
                                        onPageChange={onPageChange}
                                        onDocumentLoad={onDocumentLoad}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <SignatureModal
                        isOpen={isSignatureModalOpen}
                        close={onSignatureDraw}
                        username={username}
                        userEmail={userEmail}
                        userPhoneNumber={userPhoneNumber}
                    />
                </>
            ) : (
                <Container
                    style={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <HashLoader color="#AFB42B" size={150} />
                </Container>
            )}
        </>
    );
};
