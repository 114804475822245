const constants = {
    PAYMENT_RESULT: 'payment_result',

    STATUS: {
        NONE: 'none',
        SUCCESS: 'success',
        FAILURE: 'failure',
    },

    LOADING_STATUS: {
        NONE: 'none',
        LOADING: 'loading',
        DONE: 'done',
    },

    LOCAL_STORAGE_KEY: {
        ENROLLMENT_REDIRECT_URL: '[video_enrollment]:redirect_url',
        VERIFICATION_REDIRECT_URL: '[video_verification]:redirect_url',
    },

    TIME_DELAY: {
        LONG: 3 * 1000,
        SHORT: 250,
    },
};

export default constants;
