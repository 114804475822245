import { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

import { useScript } from '../hooks/useScript';
import { ToastContext } from '../contexts/ToastContext';
import { useGetVoiceItPhrases } from '../hooks/useGetVoiceItPhrases';

import { ESignService } from '../services/sign.service';
import { VoiceItService } from '../services/voiceit.service';

import VideoVerificationImg from '../assets/img/video-verification.jpg';

let VoiceItClient;

const { REACT_APP_VOICE_IT_API_URL, REACT_APP_VOICE_IT_CONTENT_LANGUAGE } = process.env;

export const VideoEnrollment = () => {
    const [userEmail, setUserEmail] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [isBackToVerification, setIsBackToVerification] = useState(false);

    const { phrases } = useGetVoiceItPhrases(REACT_APP_VOICE_IT_CONTENT_LANGUAGE);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        setUserEmail(params.get('email'));
        params.get('redirectUrl') && setRedirectUrl(params.get('redirectUrl'));
        setIsBackToVerification(!!params.get('success'));
    }, [location]);

    const { notify } = useContext(ToastContext);
    const voiceItScriptLoadStatus = useScript('./voiceit2.min.js');

    const createVoiceItUser = useCallback(async () => {
        if (!userEmail) return;

        // when user already has voiceItId, but didn't complete the video enrollment
        const [voiceItUserIdRes] = await ESignService.getProfile(userEmail);
        if (voiceItUserIdRes?.data?.status?.voiceitID) {
            generateVoiceItUserToken(voiceItUserIdRes?.data?.status?.voiceitID);
            return;
        }

        const [voiceItUserRes] = await VoiceItService.createUser();
        if (voiceItUserRes?.data?.responseCode === 'SUCC') {
            generateVoiceItUserToken(voiceItUserRes?.data?.userId);
            await ESignService.saveVoiceItId({
                email: userEmail,
                voiceitID: voiceItUserRes?.data?.userId,
            });
        }
    }, [userEmail]);

    const generateVoiceItUserToken = async (voiceItUserId) => {
        const [voiceItUserTokenRes] = await VoiceItService.generateUserToken(voiceItUserId);
        if (voiceItUserTokenRes?.data) {
            VoiceItClient.setSecureToken(voiceItUserTokenRes?.data);
        }
    };

    useEffect(() => {
        if (voiceItScriptLoadStatus === 'ready') {
            // eslint-disable-next-line no-undef
            VoiceItClient = new VoiceIt2.initialize(
                `${REACT_APP_VOICE_IT_API_URL}/init`,
                REACT_APP_VOICE_IT_CONTENT_LANGUAGE
            );
            VoiceItClient.setThemeColor('#0000FF');

            createVoiceItUser();
        }
    }, [createVoiceItUser, voiceItScriptLoadStatus]);

    const videoEnrollmentCallback = async (success, jsonResponse) => {
        success
            ? notify('Video Enrollments Done!')
            : notify('Video Enrollments Cancelled or Failed!');

        const videoEnrollmentStatus = success ? 'success' : 'failure';
        const [videoEnrollmentStatsToken] = await ESignService.saveVideoEnrollmentHistory({
            email: userEmail,
            status: videoEnrollmentStatus,
        });

        // if user is from video-verification, then redirect user to verification page
        if (isBackToVerification) {
            history.push(`/video-verification?email=${userEmail}`);
            return;
        }

        if (redirectUrl) {
            window.location.href = `${redirectUrl}?token=${videoEnrollmentStatsToken}`;
        }
    };

    const onVideoEnroll = async () => {
        if (!userEmail) return;

        VoiceItClient.encapsulatedVideoEnrollment({
            contentLanguage: REACT_APP_VOICE_IT_CONTENT_LANGUAGE,
            phrase: phrases[0].text,
            completionCallback: videoEnrollmentCallback,
        });
    };

    return (
        <>
            <Container className="h-100vh pt-80">
                <Row>
                    <Col sm="12" className="my-3 py-3" style={{ display: 'flex' }}>
                        <img
                            alt="Video Verification Logo"
                            src={VideoVerificationImg}
                            style={{ width: '400px' }}
                            className="mx-auto br-50"
                        />
                    </Col>
                    <Col sm="12" className="my-3 py-3" style={{ display: 'flex' }}>
                        <Button color="success" className="mx-auto w-240" onClick={onVideoEnroll}>
                            Enroll Video
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
