import { ESignApi } from './Api';
import { httpResponseDecorator } from '../utils/utils';

function getProfile(email) {
    return ESignApi.get('/users', {
        params: { email },
    });
}

function saveVoiceItId(payload) {
    return ESignApi.post('/users', payload);
}

async function saveVideoEnrollmentHistory(payload) {
    try {
        const { headers } = await ESignApi.post('/video-enrollments', payload);
        return [headers['authorization'], null];
    } catch (err) {
        return [null, err.response];
    }
}

function getVideoEnrollmentHistory({ token, email }) {
    return ESignApi.get('/video-enrollments', {
        headers: { Authorization: token },
        params: { email },
    });
}

async function saveVideoVerificationHistory(payload) {
    try {
        const { headers } = await ESignApi.post('/video-verifications', payload);
        return [headers['authorization'], null];
    } catch (err) {
        return [null, err.response];
    }
}

function getVideoVerificationHistory({ token, email }) {
    return ESignApi.get('/video-verifications', {
        headers: { Authorization: token },
        params: { email },
    });
}

function generateTokenForSign(email) {
    return ESignApi.get('/sign/token', {
        params: {
            email,
        },
    });
}

function signDocument({ token, payload }) {
    return ESignApi.post('/sign', payload, {
        headers: {
            authorization: token,
        },
        responseType: 'arraybuffer',
    });
}

function getPaymentUrl({ token, email, name }) {
    return ESignApi.post(
        '/pay',
        { email, name },
        {
            headers: {
                Authorization: token,
            },
        }
    );
}

export const ESignService = {
    getProfile: httpResponseDecorator(getProfile),
    saveVoiceItId: httpResponseDecorator(saveVoiceItId),
    saveVideoEnrollmentHistory,
    getVideoEnrollmentHistory: httpResponseDecorator(getVideoEnrollmentHistory),
    saveVideoVerificationHistory,
    getVideoVerificationHistory: httpResponseDecorator(getVideoVerificationHistory),
    signDocument: httpResponseDecorator(signDocument),
    generateTokenForSign,
    getPaymentUrl: httpResponseDecorator(getPaymentUrl),
};
