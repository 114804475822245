import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';

import SignatureCanvas from 'react-signature-canvas';

import './SignatureModal.css';

export const SignatureModal = ({ isOpen, close, username, userEmail, userPhoneNumber }) => {
    const [name, setName] = useState('');
    const [member1Name, setMember1Name] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    const canvasRef = useRef();

    useEffect(() => {
        username && setName(username);
        userEmail && setEmail(userEmail);
        userPhoneNumber && setPhoneNumber(userPhoneNumber);
    }, [username, userEmail, userPhoneNumber]);

    const onNameChange = (ev) => setName(ev.target.value);
    const onMember1NameChange = (ev) => setMember1Name(ev.target.value);
    const onEmailChange = (ev) => setEmail(ev.target.value);
    const onPhoneNumberChange = (ev) => setPhoneNumber(ev.target.value);
    const onStreetChange = (ev) => setStreet(ev.target.value);
    const onCityChange = (ev) => setCity(ev.target.value);
    const onStateChange = (ev) => setState(ev.target.value);
    const onZipCodeChange = (ev) => setZipCode(ev.target.value);

    const onSave = useCallback(() => {
        if (canvasRef.current.isEmpty()) return;

        if ([name, member1Name, email, phoneNumber, street, city, state, zipCode].some((el) => !el))
            return;

        const signatureInBase64 = canvasRef.current.getTrimmedCanvas().toDataURL();
        close(signatureInBase64, {
            name,
            member1Name,
            email,
            phoneNumber,
            street,
            city,
            state,
            zipCode,
        });
    }, [close, name, member1Name, email, phoneNumber, street, city, state, zipCode]);

    const onClear = () => canvasRef.current.clear();

    return (
        <Modal
            isOpen={isOpen}
            toggle={close}
            className="signature-modal"
            contentClassName="signature-modal-content"
        >
            <ModalHeader toggle={close}>Draw Signature</ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter your name"
                                    value={name}
                                    onChange={onNameChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="meber1Name">Member 1 Name</Label>
                                <Input
                                    type="text"
                                    name="meber1Name"
                                    id="meber1Name"
                                    placeholder="Enter Member 1 Name"
                                    value={member1Name}
                                    onChange={onMember1NameChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">Email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={onEmailChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="name">Phone Number</Label>
                                <Input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    placeholder="Enter your phone number"
                                    value={phoneNumber}
                                    onChange={onPhoneNumberChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">Street</Label>
                                <Input
                                    type="text"
                                    name="street"
                                    id="street"
                                    placeholder="Enter street"
                                    value={street}
                                    onChange={onStreetChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="name">City</Label>
                                <Input
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="Enter city"
                                    value={city}
                                    onChange={onCityChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">State</Label>
                                <Input
                                    type="text"
                                    name="state"
                                    id="state"
                                    placeholder="Enter state"
                                    value={state}
                                    onChange={onStateChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="name">Zip Code</Label>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    placeholder="Enter zip code"
                                    value={zipCode}
                                    onChange={onZipCodeChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '12px',
                        }}
                    >
                        <SignatureCanvas
                            ref={canvasRef}
                            penColor="black"
                            canvasProps={{
                                width: 500,
                                height: 160,
                                className: 'sign-canvas',
                            }}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onSave}>
                    Save
                </Button>
                <Button color="danger" onClick={onClear}>
                    Clear
                </Button>
            </ModalFooter>
        </Modal>
    );
};

SignatureModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    username: PropTypes.string,
    userEmail: PropTypes.string,
    userPhoneNumber: PropTypes.string,
};
