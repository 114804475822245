import { useEffect, useState } from 'react';

import { VoiceItService } from '../services/voiceit.service';

export const useGetVoiceItPhrases = (contentLang) => {
    const [phrases, setPhrases] = useState([]);

    const getPhrases = async (lang) => {
        if (!lang) return;

        const [phrasesRes] = await VoiceItService.getPhrasesByContentLang(lang);
        setPhrases(phrasesRes?.data || []);
    };

    useEffect(() => {
        getPhrases(contentLang);
    }, [contentLang]);

    return { phrases };
};
