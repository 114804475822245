import { VoiceItApi } from './Api';
import { httpResponseDecorator } from '../utils/utils';

function createUser() {
    return VoiceItApi.post('/users');
}

function generateUserToken(userId) {
    return VoiceItApi.post(`/users/${userId}/tokens`);
}

function getPhrasesByContentLang(contentLang) {
    return VoiceItApi.get(`/phrases/${contentLang}`);
}

export const VoiceItService = {
    createUser: httpResponseDecorator(createUser),
    generateUserToken: httpResponseDecorator(generateUserToken),
    getPhrasesByContentLang: httpResponseDecorator(getPhrasesByContentLang),
};
